// Custom variables and styles
@import 'scss/variables';

// Fonts
@import 'scss/fonts';

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// NGX Bootstrap
@import "~ngx-bootstrap/datepicker/bs-datepicker";

// Toastr
@import '~ngx-toastr/toastr-bs4-alert';

// NG-Select
@import "~@ng-select/ng-select/themes/default.theme.css";

// Custom
@import 'scss/custom-bg';
@import 'scss/custom-btns';
@import 'scss/custom-borders';
@import 'scss/custom-text';
@import 'scss/custom-forms';
@import 'scss/custom';

html, body {
    height: 100% !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.no-flex {
    flex: 0 0 auto !important;
}

.fill-flex { 
    flex: 1 !important;
    min-height: 0px !important;
}

.font-size-x-large {
    font-size: x-large;
}

.font-size-jumbo {
    font-size: 3rem;
}

.btn-receipt {
    padding-left: 6.75rem !important;
    padding-right: 6.75rem !important;
}

.btn-wide {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
}

.customer-modal { 
    max-width: 1500px !important;
}

.divider-bt {
    border-bottom: 2px solid white;
}

.quantity {
    color: #0ACC70 !important;
}

.selected-row {
    color: white;
    background-color: $light-green !important;

    a {
        color: white;
    }
}

.ng-select-container {
    border-radius: 0px;
}

.ng-option-marked {
    background-color: #0acc70 !important;
    color: white !important;
}

.ng-option-selected {
    background-color: #0acc7040 !important;
    color: #333 !important;
}

.ng-select-focused {
    color: #495057;
    background-color: #fff;
    border-color: #0acc70;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #0acc7040;
    border: 1px solid #0acc70 !important;
}

.ng-select-focused > .ng-select-container {
    box-shadow: none !important;
    border: none !important;
}

.ng-value {
    border: 1px solid #0acc70 !important;
}

.ng-value-label {
    background-color: #0acc70;
    color: white;
}

.ng-value-icon {
    background-color: #0acc70;
    border-right: 1px solid #0acc70 !important;
    color: white;

    &:hover {
        background-color: #0acc70 !important;
    }
}

.bg-grey {
    background-color: #EFEFEF;
}

.discount {
    color: #E13737;
    text-decoration: line-through;
    font-size: small;
}

.subtitle {
    color: #939598 !important
}

span {
    font-size: small;
}

.modal-close {
    font-size: x-large;
}