// Custom variables and styles
@import './variables';


.border-dark {
    border-color: $dark !important;
}

.border-default {
    border-color: $default !important;
}

.border-light-green {
    border-color: $light-green !important;
}