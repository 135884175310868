label {
    font: var(--unnamed-font-style-normal) normal bold var(--unnamed-font-size-17)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-roboto);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 17px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.page-header {
    font: var(--unnamed-font-style-normal) normal 900 var(--unnamed-font-size-17)/var(--unnamed-line-spacing-20) var(--unnamed-font-family-roboto);
    letter-spacing: var(--unnamed-character-spacing-0-44);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal 900 17px/20px Roboto;
    letter-spacing: 0.44px;
    color: black;
    opacity: 1;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}