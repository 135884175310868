body {
    border-color: #939598 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.crud-filters {
    background-color: #EFEFEF;

    .form-control {
        width: 200px;
        background-color: #EFEFEF;
        border: none;
        border-bottom: #939598;
        border-bottom-style: solid;
        border-bottom-width: medium;
    }
}

.modal-md {
    max-width: 800px !important;
}

.modal-lg {
    max-width: 1200px !important;
}

select>option:disabled {
    background-color: $default;
    color: $primary-white;
}