// Custom variables and styles
@import './variables';

.btn {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.btn-light-green {
    color: $white;
    background-color: $light-green;
    font-weight: bold;
}

.btn-outline-light-green {
    color: $light-green;
    border-color: $light-green;
    border-width: 2px;
    font-weight: bold;
}