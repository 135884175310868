// Custom variables and styles
@import './variables';

.bg-default{
    background-color: $default;
}

.bg-light-green {
    background-color: $light-green !important;
}
