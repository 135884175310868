// Custom variables and styles
@import './variables';

.text-light-green {
    color: $light-green !important;
}
.text-white {
    color: $primary-white !important;
}
.text-black {
    color: $primary-black !important;
}